import React, { useState, useRef } from 'react';

export default function CustomVideo(props) {
  const [controls, setControls] = useState({
    volume: 0.4,
    playing: false,
    focus: true
  });
  const videoRef = useRef();

  const toggleVideo = () => {
    if (videoRef.current.paused) videoRef.current.play();
    else videoRef.current.pause();
    setControls({ ...controls, playing: !videoRef.current.paused });
  };
  const turnOnFocus = () => {
    setControls({ ...controls, focus: true });
  };
  const turnOffFocus = () => {
    setControls({ ...controls, focus: false });
  };
  const onVideoEnd = () => {
    setControls({ ...controls, focus: true, playing: false });
  };
  const updateVolume = e => {
    const volume = e.target.value;
    setControls({ ...controls, volume });
    videoRef.current.volume = volume;
  };
  return (
    <figure className='video-container'>
      <video
        src={props.src}
        alt={props.alt}
        ref={videoRef}
        onEnded={onVideoEnd}
      />
      <div
        className={`video-controls ${
          controls.focus || !controls.playing ? '' : 'hidden'
        }`}
        onBlur={turnOffFocus}
        onMouseEnter={turnOnFocus}
        onMouseLeave={turnOffFocus}
        onFocus={turnOnFocus}
      >
        {controls.playing ? (
          <button
            type='button'
            className={'play-pause icon pause'}
            onClick={toggleVideo}
          />
        ) : (
          <button
            type='button'
            style={{
              backgroundImage: `url(/images/cc.svg)`
            }}
            className={'play-pause icon play'}
            onClick={toggleVideo}
          />
        )}
        <div className='volume'>
          <input
            aria-label={'volume'}
            type='range'
            className='volume-bar'
            min='0'
            max='1'
            step='0.1'
            value={controls.volume}
            onChange={updateVolume}
          />
          <div className='equalizer icon' aria-label={'mute'} title={'mute'} />
        </div>
      </div>
    </figure>
  );
}
