import React, { useState, useEffect } from 'react';
import { footerDataTransform, getSheetData, makeInnerHtml } from './utils';

export function Footer(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    getSheetData(3, footerDataTransform).then(data => {
      setData(data);
    });
  }, []);
  if (data.length < 1) {
    return '';
  }
  return (
    <div className='footer'>
      <h1>So What's Next ...</h1>
      <div className='steps'>
        {data.map(d => {
          return (
            <div
              key={d.key}
              dangerouslySetInnerHTML={makeInnerHtml(d.innerHtml)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Footer;
