import React, { useState, useEffect } from 'react';
import './App.css';
import { PolaroidText } from './Poloroid';
import Footer from './Footer';
import { getSheetData, poloroidDataTransform } from './utils';

function App() {
  const [data, setData] = useState([
    {
      key: 1,
      img: {
        src: 'https://via.placeholder.com/200x200',
        alt: '',
        polaroidText:
          'My old setup when I knew I wanted to be a web developer :D',
      },
      innerHtml:
        "<h1> Hey, <strong>Clearcover</strong> <br /> </h1> <h1>I'm Leon</h1>",
    },
    {
      key: 2,
      img: {
        src: '/images/flexibility.png',
        polaroidText: `This man alone is the reason I'm applying 📸: your site`,
      },
      innerHtml: `<h1>I want to <strong>create</strong> with <strong>awesome people</strong> who <strong> care </strong>about the project and the team.</h1>`,
    },
    {
      key: 3,
      img: {
        src: 'http://vlw2.com/example/stockimages/codersCode.jpg',
        polaroidText: `What I look like everytime it's time work.`,
      },
      innerHtml: `<h1>In short I want to code 24/7 in <strong>React and JavaScript</strong> with the peeps at <strong>Clearcover</strong>. </h1>`,
    },
  ]);
  const [jobData, setJobData] = useState([]);
  useEffect(() => {
    getSheetData(1, poloroidDataTransform).then(setData)
    getSheetData(2, poloroidDataTransform).then(setJobData)
  }, []);
  return [
    <div className='container'>
      <PolaroidList data={data} />
      <PolaroidList data={jobData} />
    </div>,
    <Footer />,
  ];
}

function PolaroidList(props) {
  return props.data.map((d) => <PolaroidText {...d} />);
}
export default App;
