import React, { useEffect, useState } from 'react';
import CustomVideo from './CustomVideo';
export function PolaroidImageVideo(props) {
  const [isVideo, setIsVideo] = useState(false);
  useEffect(() => {
    if (props.img.src.includes('.mp4')) {
      setIsVideo(true);
    }
  }, [props]);

  return (
    <div className='polaroid' style={props.style}>
      {isVideo ? (
        <CustomVideo {...props.img} />
      ) : (
        <img src={props.img.src} alt={props.img.alt} />
      )}
      <p className='text'> {props.img.polaroidText}</p>
    </div>
  );
}
