import { GoogleSpreadsheet } from 'google-spreadsheet';

export const getSheetData = async (sheetNumber, transformer) => {
  const doc = new GoogleSpreadsheet(
    process.env.REACT_APP_SHEET_CODE
  );
  await doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_EMAIL || '',
    private_key: process.env.REACT_APP_PRIVATE_KEY.replace(/\\n/g, '\n') || '',
  });
  await doc.loadInfo();
  const rows = await doc.sheetsByIndex[sheetNumber-1].getRows();
  return rows.map(transformer)
};

export const poloroidDataTransform =  (data)=> ({
  key: data.rowIndex,
  img:{
    src: data.image,
    alt: data['image-alt'],
    polaroidText: data['polaroid-text']
  },
  innerHtml: data['inner-html']
})
export const footerDataTransform = (ent) => ({
    key: ent.rowIndex,
    innerHtml: ent['inner-html'],
});



export function makeInnerHtml(html) {
  return {
    __html: html,
  };
}
