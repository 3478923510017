import React from 'react';
import PropTypes from 'prop-types';
import { PolaroidImageVideo } from './PolaroidImageVideo';
import { makeInnerHtml } from './utils';
function getRandomRotation() {
  const negative = Math.random() > 0.5 ? 1 : -1;
  return `rotate(${negative * Math.floor(Math.random() * 10) +
    negative * 3}deg)`;
}

export function PolaroidText({ img, innerHtml }) {
  const style = { transform: getRandomRotation() };
  return (
    <div className='section'>
      <PolaroidImageVideo img={img} style={style} />
      <div
        className='section-text'
        dangerouslySetInnerHTML={makeInnerHtml(innerHtml)}
      />
    </div>
  );
}

PolaroidText.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    polaroidText: PropTypes.string
  }),
  innerHtml: PropTypes.string
};

export default PolaroidText;
